import { defineNuxtPlugin } from '#imports'
      import { plugin, defaultConfig, ssrComplete } from '@formkit/vue'
      import { resetCount } from '@formkit/core'

      import importedConfig from '/home/forge/deployments/entradas/live/2024-11-14_15-28-17_5970f26/layers/forms/formkit/config.js'

      export default defineNuxtPlugin((nuxtApp) => {
        const config = defaultConfig(typeof importedConfig === 'function' ? importedConfig() : importedConfig)
        nuxtApp.hook('app:rendered', (renderContext) => {
          resetCount()
          ssrComplete(nuxtApp.vueApp)
        })
        nuxtApp.vueApp.use(plugin, config)

      })
      